import React, { PropsWithChildren } from "react";
import styles from "./Header.module.scss";

import MainMenu from "projects/common/app/header/main-menu/MainMenu";
import User from "../../features/user/User";

interface OwnProps {}

type Props = OwnProps;

const Header = (props: PropsWithChildren<Props>) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <nav className={styles.nav}>
          <MainMenu />
        </nav>
      </div>
      <div className={styles.user}>
        <User />
      </div>
    </div>
  );
};

export default Header;
