import React, { PropsWithChildren } from "react";
import Spinner from "projects/common/shared/ui/spinner/Spinner";

const LoginBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'common-login' */
      /* webpackPrefetch: 10 */
      "./Login"
    )
);

const LoginModule = () => (
  <React.Suspense fallback={<Spinner full />}>
    <LoginBundle />
  </React.Suspense>
);

export default LoginModule;
