import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StorageName } from "projects/common/types/enums/storageName";
import { Loading } from "projects/common/types/enums/loading";
import { RoleName } from "projects/common/types/enums/roleName";
import { Access, UserConfig } from "projects/common/types/interfaces/access";
import { loadStorage } from "projects/common/shared/lib/localStorage";
import Sentry from "projects/common/shared/lib/sentry";
import { getUserAccess } from "projects/common/entities/user/lib";
import { RootState } from "../../app/store";
import { Credential, User } from "projects/common/types/models/user";
import { Property } from "projects/common/types/models/user-config";
import { UserConfigProperty } from "projects/common/types/interfaces/userConfig";
import { Selector } from "projects/common/types/interfaces/helpers";
import { ReportType } from "projects/common/types/enums/report";

const userConfigDefault: UserConfig = {
  property: []
};

// Types
export interface UserState {
  data?: User | null;
  roles?: RoleName[];
  access?: Access | null;
  loading: Loading;
  dataIsEdited: boolean;
  config: UserConfig;
}

const initialState: UserState = {
  data: undefined,
  loading: Loading.Idle,
  config: userConfigDefault,
  roles: [],
  access: loadStorage<Access>(StorageName.Access),
  dataIsEdited: false
};

// Reducer
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<{ credential: Credential; config?: UserConfig }>) {
      state.data = payload?.credential?.user || null;
      state.roles = payload?.credential?.roles || [];
      state.config = payload?.config || userConfigDefault;
      state.access = getUserAccess(state.roles, state.config);
      state.loading = Loading.Succeeded;
      Sentry.setUser(payload?.credential, payload?.config);
    },
    logout(state) {
      state.data = undefined;
      state.access = undefined;
      state.roles = [];
      state.config = userConfigDefault;
      state.loading = Loading.Idle;
      Sentry.clearUser();
    }
  }
});
export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
export const userName = userSlice.name;

// Selectors
export const selectUserState = (state: RootState) => state.user;
export const selectUser = createSelector(selectUserState, (user: UserState) => user.data);
export const selectUserLoaded = createSelector(
  selectUserState,
  (user: UserState) => user.loading === Loading.Succeeded
);
export const selectUserIsEdit = createSelector(selectUserState, (user: UserState) => user.dataIsEdited);
export const selectRoles = createSelector(selectUserState, (user: UserState) => user.roles);
export const selectAccess = createSelector(selectUserState, (user: UserState) => user.access);
export const selectUserConfig = createSelector(selectUserState, (user: UserState) => user.config);
export const selectUserProperty = createSelector(
  selectUserConfig,
  (userConfig: UserConfig) => userConfig?.property || []
);

// Helper Selectors
const selectUserPropertyCode = (code: UserConfigProperty): Selector<string | undefined> =>
  createSelector(selectUserProperty, (property: Property[]) => property?.find((prop) => prop?.code === code)?.value);
const selectUserPropertyCodeArray = <T extends string>(code: UserConfigProperty): Selector<T[] | undefined> =>
  createSelector(selectUserPropertyCode(code), (propertyValue: string | undefined) => {
    if (typeof propertyValue === "undefined") {
      return undefined;
    }

    if (!propertyValue) {
      return [];
    }

    return propertyValue?.split(",") as T[];
  });

export const selectPropertyReportTypes = selectUserPropertyCodeArray<ReportType>(UserConfigProperty.reportEnabledTypes);
